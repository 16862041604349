export class ApiRoutes {
  public static DashboardBase = '/Dashboard';
  public static UsersBase = '/Users';
  public static CertifiedUsersBase = `/certified-users`;
  public static UserDashboardBase = `${ApiRoutes.CertifiedUsersBase}/dashboard`;
  public static UserProjectBase = `${ApiRoutes.CertifiedUsersBase}/project`;
  public static UserReportBase = `${ApiRoutes.CertifiedUsersBase}/reports`;
  public static AssessmentBase = '/Assessment';
  public static LoginPage = {
    GetNameFromUsername: '/Users/GetNameFromUsername',
    UserLogin: '/id-admin/login',
    RecoverPassword: '/id-admin/recover-password',
    ResetPassword: '/id-admin/reset-password',
    CheckToken: '/login/CheckToken',
    RecoverPasswordNoToken: '/id-admin/reset-password-authenticated',
    MagicLink: (token: string) => `/magic-link/verify/${token}`,
    LoginWithKey: '/id-admin/login-with-key'
  };

  public static Assessment = {
    StartAssessment: `${this.AssessmentBase}/StartAssessment`,
    GetAssessment: `${this.AssessmentBase}v2/get`,
    GetInstructions: `${this.AssessmentBase}/GetAssessmentInstructions`,
    SaveAssessment: `${this.AssessmentBase}v2/save`,
    SubmitAssessment: `${this.AssessmentBase}v2/Submit`,
    SubmitDemographics: `${this.AssessmentBase}/SubmitDemographics`
  }

  public static Dashboard = {
    GetAssessments: `${this.DashboardBase}/GetAssessments`,
    GetObservers: `${this.DashboardBase}/GetObservers`,
    GetObserverGroups: `${this.DashboardBase}/GetObserverGroups`,
    EditObserver: `${this.DashboardBase}/EditObserver`,
    DeleteObserver: `${this.DashboardBase}/DeleteObserver`,
    ResendReminder: `${this.DashboardBase}/ResendReminder`,
    GetProjectDueDate: `${this.DashboardBase}/GetProjectDueDate`,
    AddObserver: `${this.DashboardBase}/AddObserver`,
    GetObserverGroupName: `${this.DashboardBase}/GetObserverGroupName`,
    CanDeleteObserver: `${this.DashboardBase}/CanDeleteObserver`,

  }

  public static Users = {
    GetParticipantFromEmail: `${this.UsersBase}/GetParticipantFromEmail`,
  }

  public static Profile = {
    UpdateUserProfile: `${this.UsersBase}/UpdateUserProfile`,
    IsUsernameAvailable: `${this.UsersBase}/IsUsernameAvailable`,
    IsEmailAvailable: `${this.UsersBase}/IsEmailAvailable`,
    UpdateLanguage: `${this.UsersBase}/UpdateLanguage`,
    GetUserProfile: `${this.UsersBase}/GetUserProfile`,
    GetUserCertifications: `${this.UsersBase}/GetUserCertifications`,
  }

  public static CertifiedUser = {
    Dashboard: {
      GetProjects: `${ApiRoutes.UserDashboardBase}/get-projects`,
      ClientSummary: `${ApiRoutes.UserDashboardBase}/client-summary`,
      ParticipantSummary: `${ApiRoutes.UserDashboardBase}/participant-summary`,
      Pricelist: `${ApiRoutes.UserDashboardBase}/pricelist`,
      Reports: `${ApiRoutes.UserDashboardBase}/get-reports`,
      RecentAssessments: `${ApiRoutes.UserDashboardBase}/get-assessments`
    },
    Project: {
      GetParticipants: `${ApiRoutes.UserProjectBase}/project-participants`,
      GetParticipantObservers: `${ApiRoutes.UserProjectBase}/participant-observers`,
      ProjectStatusReport: `${ApiRoutes.UserProjectBase}/project-status-report`
    },
    Report: {
      GetReport: `${ApiRoutes.UserReportBase}/get-report`
    }
  }
}
