import { store } from "app/store";
import { RequireAuth } from "features/auth/auth.guard";
import React from "react";
import { RouteObject } from "react-router";

const ParticipantLayout = React.lazy(() => import('layout/participant.layout'));
const AssessmentDashboard = React.lazy(() => import('features/participant-experience/dashboard/dashboard.component'));
const AssessmentV2 = React.lazy(() => import('features/participant-experience/assessments/assessment.component'));
const AssessmentV3 = React.lazy(() => import('features/participant-experience/assessments-v3/assessment.component'));
const Profile = React.lazy(() => import('features/participant-experience/profile/profile.component'));

export const participantRoutes: RouteObject[] = [
    {
        path: '/assessments',
        element: <ParticipantLayout store={store} />,
        children: [
            { index: true, element: <RequireAuth><AssessmentDashboard /></RequireAuth> },
          { path: 'questionnaire-v3/:pqpId?', element: <RequireAuth><AssessmentV3 /></RequireAuth> },
          { path: 'questionnaire/:pqpId?', element: <RequireAuth><AssessmentV2 /></RequireAuth> }
        ]
    },
    {
        path: '/profile',
        element: <ParticipantLayout store={store} />,
        children: [
            { index: true, element: <RequireAuth><Profile /></RequireAuth> }
        ]
    }
]
