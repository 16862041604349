import {EMPTY, Observable, of} from "rxjs";
import {ajax, AjaxRequest, AjaxResponse} from "rxjs/ajax";
import {catchError, map, tap} from "rxjs/operators";
import {IGlobal} from "config/config.interface";
import {IUserState} from "../../reducers/user.slice";
import {LoaderService} from "../loader.service";
import {ToastService} from "../toast.service";
import type {IToastService} from "../toast.service";
import {inject, injectable} from "inversify";
import {IApiHeader, IApiKey, IApiServiceOptions, IHttpRequest, INewApiService} from "lib/services/api/api.interface";
import {TYPES} from "lib/ioc/ioc.types";
import {WindowContainer} from "lib/interfaces/user.interface";

@injectable()
export class NewApiService implements INewApiService {
    _user: IUserState | null;
    _activeCalls = [];
    _global = (global as any as IGlobal);
    _toastService?: IToastService;
    _loaderService?: LoaderService;
    _options: IApiServiceOptions;

    constructor() {
        // this._user = userSession ?? null;
        // if (loadDependencies) {
        //     this._authService = new AuthService();
        //     this._toastService = new ToastService();
        //     this._loaderService = new LoaderService();
        // }

      const container = WindowContainer.GetWindow().di_container;
      const toastService = container.get<IToastService>(TYPES.ToastService);
      this._toastService = toastService;

        this._user = null;
        this._options = {
            handleErrors: true
        }
    }

  setOptions(options: IApiServiceOptions) {
    this._options = {...this._options, ...options};
  }

  get<T>({url, params, base = '', isAuthenticated = true}: IHttpRequest) {
    if (!base) {
      base = this._global.config.API_URL;
    }
    return this.wrapHttpCall<T>({
      base,
      method: 'get',
      url,
      apiKey: isAuthenticated ? this.getKeyInfo() : undefined,
      data: {},
      params,
      isAuthenticated
    })
  }

  post<T>({url, data, params = null, base = '', isAuthenticated = true}: IHttpRequest) {
    if (!base) {
      base = this._global.config.API_URL;
    }

    return this.wrapHttpCall<T>(
      {
        base,
        method: 'post',
        url,
        apiKey: isAuthenticated ? this.getKeyInfo() : undefined,
        data: data,
        params,
        isAuthenticated
      }
    );
  }

  patch<T>({url, data, params = null, base = '', isAuthenticated = true}: IHttpRequest) {
    if (!base) {
      base = this._global.config.API_URL;
    }

    return this.wrapHttpCall<T>(
      {
        base,
        method: 'patch',
        url,
        apiKey: isAuthenticated ? this.getKeyInfo() : undefined,
        data: data,
        params,
        isAuthenticated
      }
    );
  }

  delete<T>({url, data, params = null, base = '', isAuthenticated = true}: IHttpRequest) {
    if (!base) {
      base = this._global.config.API_URL;
    }

    return this.wrapHttpCall<T>(
      {
        base,
        method: 'delete',
        url,
        apiKey: isAuthenticated ? this.getKeyInfo() : undefined,
        data: data,
        params,
        isAuthenticated
      }
    );
  }

  getFullPath(url: string, params?: any, base?: any) {
    if (!base) {
      base = this._global.config.API_URL;
    }
    url = base + url;
    if (params && Object.keys(params).length > 0) {
      url += "?" + new URLSearchParams(params).toString();
    }
    return url;
  }

  getKeyInfo(): IApiKey {
    if (!this._user || !this._user.id || this._user.id === 0) {
      if (!this._user) {
        this._user = this._global.store?.getState().user;
      }
    }

    if (this._user?.id === this._user?.participantId) {
      // this is a participant only account
      return {user_id: null, participant_id: this._user?.participantId ?? 0};
    }
    return {user_id: this._user?.id ?? 0, participant_id: this._user?.participantId ?? 0};
  }

  private getBearerToken() {
      if(!this._user){
        this._user = this._global.store?.getState().user;
      }
        if (this._user) {
            return `bearer ${this._user.user_token}`;
        }
        return '';
    }

    private wrapHttpCall<T>({ base, url, method, isAuthenticated, apiKey, data, params }: IHttpRequest): Observable<AjaxResponse<T>> {
        let headers: IApiHeader = {
            'content-type': 'application/json;charset=UTF-8',
        }

        if (isAuthenticated) {
            headers.Authorization = this.getBearerToken()
        }
        const request = {
            url: this.getFullPath(url, params, base),
            method,
            headers: isAuthenticated ? { ...headers, 'x-api-key': JSON.stringify(apiKey) } : headers,
            body: data,
            queryParams: params,
        } as any as AjaxRequest;

        return ajax(request)
            .pipe(
                catchError((err) => this.handleError(err)),
                map((error) => this.handleUnauthRequest(error) as any),
                tap((response) => this.handleBearerToken(response) as any)
            ) as Observable<AjaxResponse<any>>;
    }

    private handleUnauthRequest(error: any) {
        if (error && error.status === 401) {
            this._toastService?.error('Unauthorized');
             window.location.href = '/logout';
            return EMPTY;
        } else {
            return error;
        }
    }

    private handleBearerToken(response: AjaxResponse<any>) {
        if (response.request.url.indexOf(this._global.config.API_URL) === -1) return;
        if (response.xhr.getAllResponseHeaders().indexOf('authorization') === -1) return;

        const apiResponseHeader = response.xhr.getResponseHeader('authorization');
        if (apiResponseHeader) {
            if (apiResponseHeader !== 'bearer ' + this._global.store.getState().user.user_token) {
                this._global.store.dispatch({
                    type: 'user/update-token',
                    payload: apiResponseHeader.split(' ')[1]
                })
            }
        }
        return response;
    }

    private handleError(error: any) {
        if (error.status !== 200 && this._options.handleErrors) {
            this._toastService?.clearToast(true);
            this._toastService?.error('An error occurred while loading data. Please try again later.');
        }
        this._loaderService?.hide();
        if (!this._options.handleErrors)
            throw error;
        return of(error);
    }
}
