import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistor, store} from 'app/store';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {PersistGate} from 'redux-persist/integration/react';
import React, {Suspense} from 'react';
import BuildRoutingTree from 'routes/root.router';
import "reflect-metadata";
import {iocContainer} from "lib/ioc/inversify.config";

(window as any).global = require('./config/config.js');
const container = document.getElementById('root')!;
const root = createRoot(container);
(window as any).di_container = iocContainer;
if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_ENVIRONMENT === 'development' ? '' : process.env.REACT_APP_SENTRY_URL,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
    sampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ? +process.env.REACT_APP_SENTRY_SAMPLE_RATE : 0,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_RATE ? +process.env.REACT_APP_SENTRY_TRACE_RATE : 0,
    replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAY_ERROR_RATE ? +process.env.REACT_APP_SENTRY_REPLAY_ERROR_RATE : 0,
  });
}

const router = BuildRoutingTree();

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router}/>
      </Suspense>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
