import {IAppTranslations} from "lib/translations/interfaces/translations.interface";
import {AssessmentService} from "../../features/participant-experience/assessments/services/assessment.service";
import {ApiBus} from "../services/api.bus";
import {NewApiService} from "../services/api/newapi.service";
import {Container} from "inversify";

export interface IUser {
  id: number;
  type: number;
  role: number;
  roleName: string;
  participantId: number;
  isLoggedIn: boolean;
  username: string;
  firstName: string;
  lastName: string;
  fullName: string;
  languageId: number;
  languageCode: string;
  participantLanguageId: number;
  participantLanguageCode: string;
  lastActive: Date;
}

export interface IWindowContainer {
  getApiBus(): ApiBus;

  getNewApiService(): NewApiService;

  getAssessmentService(translations: IAppTranslations): AssessmentService;

  $: any;
  newApiService: NewApiService;
  assessmentService: AssessmentService;
  apiBus: ApiBus;
  di_container: Container;
}
export class WindowContainer {
  static GetWindow() {
    return (window as any as IWindowContainer);
  }
}
