import {RolloutTarget} from "lib/services/rollout/targets.enum";
import {IRolloutTargetService} from "lib/services/rollout/rollout-target.service";
import {IRolloutService} from "lib/services/rollout/rollout.service";
import {WindowContainer} from "lib/interfaces/user.interface";
import {TYPES} from "lib/ioc/ioc.types";
import {useEffect, useState} from "react";
import {ProgressSpinner} from "primereact/progressspinner";
import {useAppSelector} from "app/hooks";
import {setCustomStringProperty} from "rox-browser";

export function RolloutTargetGuard({
                                     children,
                                     flag,
                                     reroute
                                   }: { children: JSX.Element, flag: RolloutTarget, reroute?: string }) {
  const user = useAppSelector(state => state.user);
  const [viewChildren, setViewChildren] = useState(<></>);
  const container = WindowContainer.GetWindow().di_container;
  const rollout = container.get<IRolloutService>(TYPES.RolloutService);
  const rolloutTargetService = container.get<IRolloutTargetService>(TYPES.RolloutTargetService);
  setCustomStringProperty('user-id', user.id.toString());
  setCustomStringProperty('role', user.roleName);
  useEffect(() => {
    setViewChildren(
      <div className="align-items-center flex flex-grow-1 justify-content-center">
        <ProgressSpinner/>
      </div>
    );

    const rolloutFlagSelector = rolloutTargetService.selectorMap[flag];
    if (!rolloutFlagSelector || !rollout.isFlagActive(rolloutFlagSelector)) {
      setTimeout(() => {
        window.location.href = reroute ?? "/assessments";
      }, 250);
      return;
    }
    setViewChildren(children);
  }, [reroute, flag])

  return viewChildren;
}
