import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from './app/hooks';
import { IGlobal } from './config/config.interface';


function App() {
  const user = useAppSelector((state) => state.user);
  const globalConfig = (global as any as IGlobal);
  const navigate = useNavigate();

  useEffect(() => {
    // Do our base routing here
    if (user.type === 1){
      navigate('/assessments');
    } else {
      // navigate('/manage');
      setTimeout(() => {
        window.location.href = globalConfig.config.URL_CERTIFIED_USER + "/Default.aspx?_auth=" + user.id;
      }, 100);
    }
  }, [user])



  // let element = useRoutes(routes);

  return (
    <>
    </>
  )

}

export default App;
