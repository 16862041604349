import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {IGlobal} from "config/config.interface";
import {ApiRoutes} from "lib/services/api.routes";
import {
  ApiDataGridResponse,
  ClientSummary, DashboardAssessments,
  DashboardProject, DashboardReports, ParticipantObserver,
  ParticipantSummary, ProjectParticipants, IStatusReport
} from "lib/interfaces/certified-user-api.interface";
import {IUserState, selectAuthToken} from "lib/reducers/user.slice";
import {
  Assessment,
  InstructionsModel
} from "../../features/participant-experience/assessments-v3/interfaces/assessment.interface";

const globalConfig = (global as any) as IGlobal;

// @ts-ignore
export const questApi = createApi({
  reducerPath: 'questApi',
  baseQuery: fetchBaseQuery({
    baseUrl: globalConfig.config.API_URL,
    prepareHeaders: (headers, {getState}: any) => {
      const authToken = selectAuthToken(getState());
      if (authToken) {
        headers.set('Authorization', `Bearer ${authToken}`)
      }
    }
  }),
  endpoints: (builder) => ({
    getDashboardProjects: builder.query<ApiDataGridResponse<DashboardProject>, any>({
      query: () => ApiRoutes.CertifiedUser.Dashboard.GetProjects
    }),
    getClientSummary: builder.query<ClientSummary, any>({
      query: (orgId) => `${ApiRoutes.CertifiedUser.Dashboard.ClientSummary}?clientId=${orgId}`
    }),
    getParticipantSummary: builder.query<ParticipantSummary, any>({
      query: (participantId) => `${ApiRoutes.CertifiedUser.Dashboard.ParticipantSummary}?participantId=${participantId}`
    }),
    getPriceListPerm: builder.query<string, any>({
      query: () => `${ApiRoutes.CertifiedUser.Dashboard.Pricelist}`
    }),
    getProjectParticipants: builder.query<ProjectParticipants[], any>({
      query: (projectId) => `${ApiRoutes.CertifiedUser.Project.GetParticipants}?projectId=${projectId}`
    }),
    getParticipantObservers: builder.query<ParticipantObserver[], any>({
      query: ({projectId, targetId}) => `${ApiRoutes.CertifiedUser.Project.GetParticipantObservers}?projectId=${projectId}&targetParticipant=${targetId}`
    }),
    getRecentReports: builder.query<ApiDataGridResponse<DashboardReports>, any>({
      query: () => `${ApiRoutes.CertifiedUser.Dashboard.Reports}`
    }),
    getRecentAssessments: builder.query<ApiDataGridResponse<DashboardAssessments>, any>({
      query: () => `${ApiRoutes.CertifiedUser.Dashboard.RecentAssessments}`
    }),
    getProjectStatusReport: builder.query<ApiDataGridResponse<IStatusReport>, any>({
      query: (projectId) => `${ApiRoutes.CertifiedUser.Project.ProjectStatusReport}?projectId=${projectId}`
    }),

    getAssessment: builder.query<Assessment, any>({
      query: ({pqp, culture}) => `${ApiRoutes.Assessment.GetAssessment}?pqpid=${pqp}&cultureId=${culture}`
    }),
    getAssessmentInstructions: builder.query<InstructionsModel, any>({
      query: ({productId, questionnaireId, pqpId, cultureId}) => `${ApiRoutes.Assessment.GetInstructions}?ProductID=${productId}&QuestionnaireID=${questionnaireId}&PQPID=${pqpId}&cultureId=${cultureId}`
    })
  })
})

export const {
  useGetDashboardProjectsQuery,
  useGetClientSummaryQuery,
  useGetParticipantSummaryQuery,
  useGetPriceListPermQuery,
  useGetProjectParticipantsQuery,
  useGetParticipantObserversQuery,
  useGetRecentReportsQuery,
  useGetRecentAssessmentsQuery,
  useGetProjectStatusReportQuery,
  useGetAssessmentQuery,
  useGetAssessmentInstructionsQuery
} = questApi;
