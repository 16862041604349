/* ******************************************************************
    DO ANY WORK HERE
****************************************************************** */
let airtableAccountNumber = "appdQlhZri1S0G3Wb";
let airtableApiKey = "patfwomdonUh1trRn.36a8c84c6f1000ae830e40d1b59e32e524a50469c3d5ba497a4b20652d1b5865";

//let adobePdfKey = "73ebd234025c4d988e15841d0c123475"; //staging
//let adobePdfKey = "da6490e7151147e398336cc3601d632f"; //production


/* ******************************************************************
    EXPORT JSON HERE
****************************************************************** */
module.exports = global.config = {
  /*
i18n: {
      welcome: {
          en: "Welcome",
          fa: "خوش آمدید"
      }
      // rest of your translation object
  }
*/

  /* ******************************************************************
        URLs & Links
    ****************************************************************** */
  // MRG API URL
  API_URL: process.env.REACT_APP_API_URL,

  // AirTable (page translations)
  AIRTABLEAPI_URL: "https://api.airtable.com/v0/" + airtableAccountNumber,
  AIRTABLEAPI_KEY: "api_key=" + airtableApiKey,
  AIRTABLE_KEY: airtableApiKey,
  AIRTABLEAPI_MAX_RECORDS: 150,
  AIRTABLE_BASE: airtableAccountNumber,

  // MRG "certified user" site
  URL_CERTIFIED_USER: process.env.REACT_APP_URL_CERTIFIED_USER,

  // MRG's Privacy Policy
  URL_PRIVACY_POLICY: "https://questv2.mrg.com/Pages/Privacy.aspx",
  URL_PRICE_LIST: process.env.REACT_APP_URL_PRICE_LIST,


  /* ******************************************************************
        GENERAL GLOBAL VARIABLES
    ****************************************************************** */
  MRG_COLOR_BLUE_DARK: '#426da9',

  MRG_FALSE: 0,
  MRG_TRUE: 1,

  MRG_YES: 1,
  MRG_NO: 2,

  MRG_ALWAYS: 1,
  MRG_NEVER: 2,
  MRG_SOMETIMES: 3,

  OTHER_CHOICE: 999,
  UNCHOSEN: 99, // Used for multiple choice pull-downs
  UNSET: 99, // Used for number of observers set = unset (aka, no observers added - this is actually different than zero observers added)
  UNANSWERED: 0,


  /* ******************************************************************
      MRG SPECIFIC GLOBAL VARIABLES
  ****************************************************************** */
  GENERIC_PARTICIPANT_USERNAME: "usemrg", //keep lowercase, even if it's uppercase in the db

  MIN_USERNAME_LENGTH: 2,

  VALID_TOKEN_TIME: 60,

  // Number of days after a logged-in user was last active
  // before making them log back in
  VALID_LOGIN_ACTIVE_DAYS: 7,

  // Number of days after an assessment due date that a user
  // has to finish it before it becomes expired and unavailable
  DAYS_ASSESSMENT_EXPIRES_AFTER_DUE_DATE: 90,

  // Number of days after an assessment due date that it is hidden
  DAYS_ASSESSMENT_REMOVED_AFTER_DUE_DATE: 365,

  // Number of days a completed demographics questionnaire is valid
  DAYS_DEMOGRAPHICS_IS_VALID_AFTER_COMPLETION: 365,

  // The name used in the browser's local storage to store the global "user" state
  //LOCAL_STORAGE_KEY: "persist:quest",

  // User Types: 1=participant, 2=certified user, 3=both
  USER_TYPE_PARTICIPANT: 1,
  USER_TYPE_CERTIFIEDUSER: 2,
  USER_TYPE_BOTH: 3,

  // User Roles: 4=MRG Customer Service, 5=Certified Facilitator
  USER_ROLE_MRG_CS: 4,
  USER_ROLE_FACILITATOR: 5,


  /* ******************************************************************
      MRG's LANGUAGE AND STATE IDs
  ****************************************************************** */
  LANGUAGES: [
    /* 0 */  {id: 0, code: 'en-US', iso:'enUS', iso2: 'EN', iso3: 'ENG', name: 'English (United States)', name1: 'English (US)', name2: '', nameEnglish: 'English (US)' },
    /* 1 */  {id: 1, code: 'en-US', iso:'enUS', iso2: 'EN', iso3: 'ENG', name: 'English (United States)', name1: 'English (US)', name2: '', nameEnglish: 'English (US)' },
    /* 2 */  {id: 2, code: 'da-DK', iso:'da', iso2: 'DA', iso3: 'DAN', name: 'Dansk (Danmark)', name1: 'Dansk', name2: '(Danmark)', nameEnglish: 'Danish' },
    /* 3 */  {id: 3, code: 'de-DE', iso:'de', iso2: 'DE', iso3: 'DEU', name: 'Deutsch (Deutschland)', name1: 'Deutsch', name2: '(Deutschland)', nameEnglish: 'German' },
    /* 4 */  {id: 4, code: 'es-ES', iso:'es', iso2: 'ES', iso3: 'SPA', name: 'Español (España)', name1: 'Español', name2: '(España)', nameEnglish: 'Spanish' },
    /* 5 */  {id: 5, code: 'fr-FR', iso:'fr', iso2: 'FR', iso3: 'FRA', name: 'Français (France)', name1: 'Français', name2: '(France)', nameEnglish: 'French' },
    /* 6 */  {id: 6, code: 'it-IT', iso:'it', iso2: 'IT', iso3: 'ITA', name: 'Italiano (Italia)',  name1: 'Italiano', name2: '(Italia)', nameEnglish: 'Italian' },
    /* 7 */  {id: 7, code: 'nl-NL', iso:'nl', iso2: 'NL', iso3: 'NLD', name: 'Nederlands (Nederland)', name1: 'Nederlands', name2: '(Nederland)', nameEnglish: 'Dutch' },
    /* 8 */  {id: 8, code: 'pt-BR', iso:'pt', iso2: 'PT', iso3: 'POR', name: 'Português (Brasil)', name1: 'Português', name2: '(Brasil)', nameEnglish: 'Portuguese' },
    /* 9 */  {id: 9, code: 'sv-SE', iso:'sv', iso2: 'SV', iso3: 'SWE', name: 'Svenska (Sverige)', name1: 'Svenska', name2: '(Sverige)', nameEnglish: 'Swedish' },
    /* 10 */ {id: 10, code: 'zh-CN', iso:'zhCN', iso2: 'ZH', iso3: 'ZHO', name: '中文(简体)', name1: '中文',  name2: '(简体)', nameEnglish: 'Chinese (Simplified)' },
    /* 11 */ {id: 11, code: 'en-GB', iso:'enGB', iso2: 'EN2', iso3: 'ENG2', name: 'English (United Kingdom)', name1: 'English (UK)', name2: '', nameEnglish: 'English (UK)' },
    /* 12 */ {id: 12, code: 'pl-PL', iso:'pl', iso2: 'PL', iso3: 'POL', name: 'Polski (Polska)', name1: 'Polski', name2: '(Polska)', nameEnglish: 'Polish' },
    /* 13 */ {id: 13, code: 'ja-JP', iso:'ja', iso2: 'JA', iso3: 'JPN', name: '日本語 (日本)', name1: '日本語 (日本)', name2: '', nameEnglish: 'Japanese' },
    /* 14 */ {id: 14, code: 'fi-FI', iso:'fi', iso2: 'FI', iso3: 'FIN', name: 'Suomalainen (Suomi)', name1: 'Suomalainen', name2: '(Suomi)', nameEnglish: 'Finnish' },
    /* 15 */ {id: 15, code: 'cs-CZ', iso:'cs', iso2: 'CS', iso3: 'CES', name: 'Česká (Česká republika)', name1: 'Česká', name2: '(Česká republika)', nameEnglish: 'Czech' },
    /* 16 */ {id: 16, code: 'nb-NO', iso:'nb', iso2: 'NO', iso3: 'NOR', name: 'Norsk (Norge)', name1: 'Norsk', name2: '(Norge)', nameEnglish: 'Norwegian' },
    /* 17 */ {id: 17, code: 'ko-KR', iso:'ko', iso2: 'KO', iso3: 'KOR', name: '한국인 (한국)', name1: '한국인', name2: '(한국)', nameEnglish: 'Korean' },
    /* 18 */ {id: 18, code: 'ar-SA', iso:'arSA', iso2: 'AR', iso3: 'ARA', name: 'العربية (لبنان)‏', name1: 'العربية‏', name2: '(لبنان)‏', nameEnglish: 'Arabic' },
    /* 19 */ {id: 19, code: 'zh-TW', iso:'zhTW', iso2: 'ZH2', iso3: 'ZHO2', name: '中文(繁體)', name1: '中文 (繁體)', name2: '', nameEnglish: 'Chinese (Traditional)' },
    /* 20 */ {id: 20, code: 'ru-RU', iso:'ru', iso2: 'RU', iso3: 'RUS', name: 'Russian (Russia)', name1: 'Russian', name2: '(Russia)', nameEnglish: 'Russian' },
  ],
  LANGUAGE_ENGLISH_US: 1,
  LANGUAGE_DANISH: 2,
  LANGUAGE_GERMAN: 3,
  LANGUAGE_SPANISH: 4,
  LANGUAGE_FRENCH: 5,
  LANGUAGE_ITALIAN: 6,
  LANGUAGE_DUTCH: 7,
  LANGUAGE_PORTUGUESE: 8,
  LANGUAGE_SWEDISH: 9,
  LANGUAGE_CHINESE_SIMPLIFIED: 10,
  LANGUAGE_ENGLISH_UK: 11,
  LANGUAGE_POLISH: 12,
  LANGUAGE_JAPANESE: 13,
  LANGUAGE_FINNISH: 14,
  LANGUAGE_CZECH: 15,
  LANGUAGE_NORWEGIAN: 16,
  LANGUAGE_KOREAN: 17,
  LANGUAGE_ARABIC: 18,
  LANGUAGE_CHINESE_TRADITIONAL: 19,
  LANGUAGE_RUSSIAN: 20,

  /* COUNTRIES */
  FRANCE: 75,
  UNITED_STATES: 233,


  /* ******************************************************************
      PRODUCTS
      •	All IDI products: Yellow (#FBDD40 == $mrg-yellow)
      •	All LEA products: Green (#79c000 == $mrg-green)
      •	All Personal Directions products: Teal (#00B388 == $mrg-teal)
      •	All Service Excellence products: Medium blue (#04A9C7 == $mrg-blue-medium)
      •	All SPA products: Light blue (#71C5E8 == $mrg-blue-light)
      •	All Business Development: include with SPA (per Lino 3/29/22 - BD is an old product)
  ****************************************************************** */
  PRODUCTS: [
    /* 0 */  { id: 0, code: 'XYZ',    class: 'na',  color: 'black',   font: 'white', certBadge: 'badge_temp.png' },
    /* 1 */  { id: 1, code: 'PD',     class: 'pd',  color: '#00B388', font: 'white', certBadge: 'badge_certified_personal_directions.png' },
    /* 2 */  { id: 2, code: 'LEA',    class: 'lea', color: '#79c000', font: 'white', certBadge: 'badge_certified_LEA.png' },
    /* 3 */  { id: 3, code: 'SPASD',  class: 'spa', color: '#71C5E8', font: 'black', certBadge: '' },
    /* 4 */  { id: 4, code: 'SPA',    class: 'spa', color: '#71C5E8', font: 'black', certBadge: 'badge_certified_SPA.png' },
    /* 5 */  { id: 5, code: 'LEA360', class: 'lea', color: '#79c000', font: 'white', certBadge: '' },
    /* 6 */  { id: 6, code: 'IDI',    class: 'idi', color: '#FBDD40', font: 'black', certBadge: 'badge_certified_IDI.png' },
    /* 7 */  { id: 7, code: 'LEASD',  class: 'lea', color: '#79c000', font: 'white', certBadge: '' },
    /* 8 */  { id: 8, code: 'LEACU',  class: 'lea', color: '#79c000', font: 'white', certBadge: '' },
    /* 9 */  { id: 9, code: 'LEARE',  class: 'lea', color: '#79c000', font: 'white', certBadge: '' },
    /* 10 */ { id: 10, code: 'BDPA',   class: 'bd',  color: '#71C5E8', font: 'black', certBadge: '' },
    /* 11 */ { id: 11, code: 'BDPASD', class: 'bd',  color: '#71C5E8', font: 'black', certBadge: '' },
    /* 12 */ { id: 12, code: 'SE',     class: 'se',  color: '#04A9C7', font: 'white', certBadge: '' },
    /* 13 */ { id: 13, code: 'SESD',   class: 'se',  color: '#04A9C7', font: 'white', certBadge: '' },
  ],
  PRODUCT_PD: 1,
  PRODUCT_LEA: 2,
  PRODUCT_SPASD: 3,
  PRODUCT_SPA: 4,
  PRODUCT_LEA360: 5,
  PRODUCT_IDI: 6,
  PRODUCT_LEASD: 7,
  PRODUCT_LEACU: 8,
  PRODUCT_LEARE: 9,
  PRODUCT_BDPA: 10,
  PRODUCT_BDPASD: 11,
  PRODUCT_SE: 12,
  PRODUCT_SESD: 13,

  OBSERVER_TYPE_SELF: 1,
  OBSERVER_TYPE_BOSS: 2,
  OBSERVER_TYPE_PEER: 3,
  OBSERVER_TYPE_DIRECT_REPORT: 4,


  /* ******************************************************************
      ASSESSMENT QUESTIONS
  ****************************************************************** */

  // Assessment Type
  ASSESSMENT_TYPE_ALL: 0,
  ASSESSMENT_TYPE_ACTIVE: 3,
  ASSESSMENT_TYPE_COMPLETED: 4,

  // Assessments Status
  ASSESSMENT_STATUS_NEW: 1,
  ASSESSMENT_STATUS_REOPENED: 2,
  ASSESSMENT_STATUS_INPROGRESS: 3,
  ASSESSMENT_STATUS_COMPLETED: 4,
  ASSESSMENT_STATUS_EXPIRED: 5,
  ASSESSMENT_STATUS_DONE: 6,

  QUESTIONNAIRE_TYPE: [
    /* 0 */  {id: 0, name: '' },
    /* 1 */  {id: 1, name: 'Demographics' },
    /* 2 */  {id: 2, name: 'Assessment' },
    /* 3 */  {id: 3, name: 'Global Demographics' },
    /* 4 */  {id: 4, name: 'Custom' },
    /* 5 */  {id: 5, name: 'Part X Custom' },
    /* 6 */  {id: 6, name: 'Part X Demographics' },
    /* 7 */  {id: 7, name: 'Part R' },
  ],
  QUESTIONNAIRE_TYPE_DEMOGRAPHICS: 1,
  QUESTIONNAIRE_TYPE_ASSESSMENT: 2,
  QUESTIONNAIRE_TYPE_GLOBAL_DEMOGRAPHICS: 3,
  QUESTIONNAIRE_TYPE_CUSTOM: 4,
  QUESTIONNAIRE_TYPE_PART_X: 5,
  QUESTIONNAIRE_TYPE_PART_X_DEMOGRAPHICS: 6,
  QUESTIONNAIRE_TYPE_PART_R: 7,

  QUESTIONNAIRE_ID_DEMOGRAPHICS_SE: 33,
  QUESTIONNAIRE_ID_DEMOGRAPHICS: 111,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_SPA: 112,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_SPASD: 113,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_PD: 114,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_LEARE: 115,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_LEA360: 116,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_SPA_OBSERVER: 1196,
  QUESTIONNAIRE_ID_DEMOGRAPHICS_BDPA: 1450,
  QUESTIONNAIRE_ID_PRODUCT_DEMOGRAPHICS: [
    /*QUESTIONNAIRE_ID_DEMOGRAPHICS_SE,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_SPA,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_SPASD,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_PD,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_LEARE,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_LEA360,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_SPA_OBSERVER,
    QUESTIONNAIRE_ID_DEMOGRAPHICS_BDPA,*/
    33, 112, 113, 114, 115, 116, 1196, 1450,
  ],

  QUESTIONNAIRE_IDS_PART_B: [27, 47, 48, 1198],
  QUESTIONNAIRE_IDS_PART_R: [119, 120, 121, 125, 126, 127],
  QUESTIONNAIRE_IDS_PARTS_B_AND_R: [27, 47, 48, 119, 120, 121, 125, 126, 127, 1198],

  QUESTION_UNANSWERED: 0,
  QUESTION_HALFANSWERED: 2,
  QUESTION_FULLYANSWERED: 1,

  QUESTION_TYPE: [
    /* 0 */  {id: 0, code: '', name: '' },
    /* 1 */  {id: 1, code: 'FC', name: 'Forced Choice' },
    /* 2 */  {id: 2, code: 'AR', name: 'Anchored Rating' },
    /* 3 */  {id: 3, code: '', name: '' },
    /* 4 */  {id: 4, code: 'MC', name: 'Multiple Choice' },
    /* 5 */  {id: 5, code: 'OE', name: 'Open Ended' },
    /* 6 */  {id: 6, code: 'MS', name: 'Multiple Selection' },
    /* 7 */  {id: 7, code: 'RD', name: 'Radio' },
    /* 8 */  {id: 8, code: 'SL', name: 'Single Line Text' },
  ],
  QUESTION_TYPE_FC: 1,
  QUESTION_TYPE_AR: 2,
  QUESTION_TYPE_MC: 4,
  QUESTION_TYPE_OE: 5,
  QUESTION_TYPE_MS: 6,
  QUESTION_TYPE_RAIDO: 7,
  QUESTION_TYPE_SINGLELINE: 8,

  DEMOGRAPHICS_QUESTIONNAIRE_NEW: 1, //Taking the demograohics assessment for the first time
  DEMOGRAPHICS_QUESTIONNAIRE_COMPLETED: 2, //In the main assessment and completed the demographics
  DEMOGRAPHICS_QUESTIONNAIRE_SAME_PROJECT: 3, //In an assessment that is part of the same project and completed the demographics
  DEMOGRAPHICS_QUESTIONNAIRE_DIFFERENT_PROJECT: 4, //In a new project, but completed demographics in another project

  DEMOGRAPHICS_QUESTION_ETHNICITY_TEXT: 59195,
  DEMOGRAPHICS_QUESTION_ETHNICITY_MC: 59196,
  DEMOGRAPHICS_QUESTION_RACE: 59198,
  DEMOGRAPHICS_QUESTION_AGE: 59203,
  DEMOGRAPHICS_QUESTION_ARE_YOU_MANAGER: 59207,
  DEMOGRAPHICS_QUESTION_YEARS_MGNT_EXPERIENCE: 59208,
  DEMOGRAPHICS_QUESTION_NUMBER_OF_REPORTS: 59209,
  DEMOGRAPHICS_QUESTION_COUNTRY_WORKING: 59230,
  DEMOGRAPHICS_QUESTION_WORK_REMOTELY: 59405,
  DEMOGRAPHICS_QUESTION_DAYS_PER_WEEK: 59406,
  DEMOGRAPHICS_QUESTION_NATIONALITY: 59197,
  DEMOGRAPHICS_QUESTION_JOB_TITLE: 59199,
  DEMOGRAPHICS_QUESTION_FUNCTIONAL_AREA: 59201,
  DEMOGRAPHICS_QUESTION_LEVEL: 59202,
  DEMOGRAPHICS_QUESTION_GENDER: 59204,
  DEMOGRAPHICS_QUESTION_TIME_IN_POSITION: 59205,
  DEMOGRAPHICS_QUESTION_TIME_IN_ORG: 59206,

  DEMOGRAPHICS_QUESTION_RACE_OTHER_OPTION: 7,
  DEMOGRAPHICS_ETHNICITY_ALLOWED_COUNTRIES: [14, 40, 105, 156, 202, 232]
};
