import {Flag, register, setup, showOverrides} from "rox-browser";
import {from, Observable} from "rxjs";
import {mapTo} from 'rxjs/operators';
import {InitializeRolloutFlags, RolloutFlags} from "lib/services/rollout/flags";
import {injectable} from "inversify";

export interface IRolloutService {
  initRollout(
    key: string | undefined,
    defaults: () => RolloutFlags,
    allowDebugMenu: boolean): Observable<null>;

  isFlagActive(selector: (flags: RolloutFlags) => Flag): boolean;
}

@injectable()
export class RolloutService implements IRolloutService {
  private flagState: RolloutFlags;
  private document: Document;

  constructor() {
    this.document = (window as any).document;
    this.flagState = {} as RolloutFlags;
    this.initRollout(process.env.REACT_APP_ROLLOUT_KEY, InitializeRolloutFlags, process.env.REACT_APP_ENVIRONMENT !== 'production')
  }

  initRollout(
    key: string | undefined,
    defaults: () => RolloutFlags,
    allowDebugMenu: boolean = false): Observable<null> {
    if (!key) throw "Unable to initialize Rollout. No key provided";

    if (allowDebugMenu) {
      const openDebugMenu: (e: KeyboardEvent) => void = (e: KeyboardEvent) => {
        if (e.altKey && e.key === '`') {
          showOverrides();
        }
      };
      this.document.addEventListener('keyup', openDebugMenu, false);
    }

    this.flagState = defaults();
    for (const container in this.flagState) {
      if (this.flagState.hasOwnProperty(container)) {
        register(container, this.flagState[container]);
      }
    }

    return from(setup(key, {})).pipe(mapTo(null));
  }

  isFlagActive(selector: (flags: RolloutFlags) => Flag): boolean {
    const flag = selector(this.flagState);
    return flag.isEnabled();
  }
}
