import {IGlobal, IToastr} from "config/config.interface";
import {injectable} from "inversify";

export interface IToastService {
  error(message: string): void;

  success(message: string): void;

  infoWithTimer(message: string, timer: number): void;

  clearToast(lastOnly: boolean): void;

  showToast(type: string, message: string, options: any): void;
}

@injectable()
export class ToastService implements IToastService {
    _global = (global as any as IGlobal);
    _lastToast: any;

    /**
     *
     */
    constructor() {
        this._global.config.toastr = toastr as IToastr;
        this._global.config.toastr.options = {};
    }

  error(message: string) {
    this.showToast('error', message);
  }

  success(message: string) {
    this.showToast('success', message);
  }

  infoWithTimer(message: string, timer: number = 7500) {
    this._global.config.toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: 0,
      extendedTimeOut: 0,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      tapToDismiss: false
    };

    this.showToast('info', message);
    this._global.config.toastr.options = {};
  }

  clearToast(lastOnly: boolean = false) {
    if (lastOnly) {
      this._global.config.toastr.clear(this._lastToast);
    } else {
      this._global.config.toastr.clear();
    }
  }

  showToast(type: string, message: string, options: any = {}): void {
    this._lastToast = (this._global.config.toastr as any)[type](message, options);
  }
}
