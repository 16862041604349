import {IAppTranslations} from "lib/translations/interfaces/translations.interface";

export interface Assessment {
  Product: Product;
  Project: Project;
  OrgID: number;
  PQPID: number;
  CompletedDate: null;
  DueDate: Date;
  IsObserverQuestionnaire: boolean;
  ObserverType: number;
  TargetName: string;
  CompletedQuestions: number;
  TotalQuestions: number;
  ShowDemographicsStatusID: number;
  Languages: Language[];
  Questionnaires: Questionnaire[];
  ObserverLabel: string;
  ParticipantId: number;
  TargetParticipantId: number;
  CultureId: number;

  // used by UI
  displayed_instructions?: number;
  first_unanswered_question?: Question;
  expanded_questionnaires?: number[];
  question_alert?: number;
  sections: Section[];
  highlight_unanswered?: boolean;
  submitting?: boolean;
  is_complete?: boolean;
  saving?: boolean;
  save_state: SaveState;
  question_queue: number;
}

export interface SaveState {
  state: AssessmentSaveState;
  message: string;
}

export enum AssessmentSaveState {
  none,
  saving,
  success,
  error
}

export interface Section {
  Questionnaires: Questionnaire[];
  expanded: boolean;
  id: number;
}

export interface Language {
  CultureCode: string;
  CultureName: string;
  CultureNameEnglish: string;
}

export interface Product {
  ProductID: number;
  Name: string;
  Purpose: string;
}

export interface Project {
  TargetDate: Date;
  ProjectID: number;
}

export interface Questionnaire {
  QuestionnaireId: number;
  Questions: Question[];
  TotalQuestions: Question[];
  Purpose: null | string;
  CueingStatement: null | string;
  Instructions: null | string;
  QuestionnaireType: number;
}

export interface Question {
  QuestionId: number;
  Answered: number;
  CustomSort: boolean;
  FirstQuestionInAssessment: boolean;
  FirstQuestionInQuestionnaire: boolean;
  HideNoComment: boolean;
  LastQuestionInQuestionnaire: boolean;
  QuestionHeadingText: string | null;
  QuestionStatement: string;
  QuestionTypeId: number;
  QuestionnaireId: number;
  Row: number;
  SortOrder: number;
  Options: QuestionOption[];
}

export interface QuestionOption {
  Value: number | null;
  OptionOrdinal?: number;
  QuestionOptionStatement?: string;
  TextValue?: string | null;
  Answer: number;
}

export interface IPEModal {
  toggle: Function;
  isOpen: boolean;
  translations: IAppTranslations;
}

export interface InstructionsModel {
  CueingStatement: string;
  Instructions: string;
}
